import React from "react";
import { BrowserRouter } from "react-router-dom";
import { MuiThemeProvider } from "../../providers/mui-theme/mui-theme.provider";
import AppBarMenu from "../appbar/appbar";
import AppRoutes from "./app.routes";
import { Provider } from "react-redux";
import { store } from "../../redux/store";

type Props = {};
export const AppComponent: React.FC<Props> = () => {
  return (
    <BrowserRouter>
      <MuiThemeProvider >
      <Provider store={store}>
        <AppRoutes />
        </Provider>
      </MuiThemeProvider>
    </BrowserRouter>

  );
};

export default AppComponent;
