import React from "react";
import { useRoutes, RouteObject } from "react-router-dom";
import { PATHS } from "../../config/paths.config";
import SignLayout from "../../layouts/sign.layout";
import RequireAuth from "../../providers/authentication/require-auth.protector";


const HomePage = React.lazy(() => import("../../pages/home.page"));
const SignInPage = React.lazy(() => import("../../pages/sign-in.page"));
const DashboardPage = React.lazy(() => import("../../pages/dashboard.page"));


const routes: RouteObject[] = [
  {
    path: "/",
    element: <SignInPage />,
  },
  {
    path: "/home",
    element: <HomePage />,
  },
  {
    path: "/dashboard",
    element: <DashboardPage />,
  },
  /*{
    element: <SignLayout />,
    children: [
     
        {
            path: PATHS.getHomepage(),
            element: <HomePage />,
        }, 
        {
            path: PATHS.getSignIn(),
            element: <SignInPage />,
        },
         
    ],
    
  }*/
];

type Props = {};
const AppRoutes: React.FC<Props> = () => useRoutes(routes);

export default AppRoutes;
