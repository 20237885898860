import type { PayloadAction } from "@reduxjs/toolkit";
import { Menu, MenuState } from "./menu-reducer.types";

export const menuActions = {
    setMenu: (
        state: MenuState,
        {
            payload,
        }: PayloadAction<{
            menu: Menu[];
        }>
    ) => {
       state.menu = payload.menu;
    },
    menuIsLoading: (
        state: MenuState,
        {payload}: PayloadAction<boolean>
    )=> {
        state.loading = payload;
    }
}