import { ThemeOptions } from "@mui/material/styles";

const lightTheme: ThemeOptions = {
  palette: {
    mode: "light",
    background: {
      default: "#e6e8e9",
      paper: "#FFF",
    },
    primary: {
      main: "#1e9ee3",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#e3631e",
      contrastText: "#FFF",
    },
    text: {
      primary: "#373738",
      secondary: "#6d6d6e",
      disabled: "#ababad",
    },
    error: { main: "#e03b3b", contrastText: "#fff" },
    success: { main: "#4fbd54", contrastText: "#fff" },


  },

};

export default lightTheme;
