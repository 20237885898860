import { createSlice } from "@reduxjs/toolkit";
import { MenuState } from "./menu-reducer.types";
import { menuActions } from "./menu.actions";

const initialState: MenuState = {
    menu: [],
    loading: true,
};

export const menuSlice = createSlice({
    name: "menu",
    initialState,
    reducers: menuActions,
  });
  
  export const { setMenu, menuIsLoading } = menuSlice.actions;
  
  export default menuSlice.reducer;
  